import { createRouter, createWebHistory } from 'vue-router'
import { trackLastRoute } from '@/modules/base/composable/useRouterHistory'
import routes from './routes'
import {
  patchMissingCompanyId,
  companyAccessGuard,
  companySwitchRedirect,
} from './utils/companyNavigationGuard'
import { featuresAccessGuard } from './utils/featuresAccessGuard'
import { trackNavigation } from './utils/navigationTracker'
import { notFoundPrefetchGuard } from './utils/notFoundPrefetchGuard'
import { scrollBehavior } from './utils/scrollBehavior'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
})

router.beforeEach(patchMissingCompanyId)
router.beforeEach(companyAccessGuard)
router.beforeEach(companySwitchRedirect)
router.beforeEach(featuresAccessGuard)
router.beforeEach(notFoundPrefetchGuard)
router.afterEach(trackLastRoute)
router.afterEach(trackNavigation)

export default router
